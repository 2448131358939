import React from 'react';
import { string, shape } from 'prop-types';

import Button from '../../Button';

import './style.scss';

const propTypes = {
  title: string.isRequired,
  image: shape({
    source: string.isRequired,
    alt: string,
  }).isRequired,
  text: string.isRequired,
  url: string.isRequired,
  ctaLabel: string.isRequired,
};

const FeatureCard = ({ image, title, text, url, ctaLabel }) => {
  return (
    <div className="FeatureCard">
      <img className="FeatureCard__image" src={ image.source } alt={ image.alt || title }/>
      <h4 className="FeatureCard__title">{ title }</h4>
      <p className="FeatureCard__text">{ text }</p>
      <Button secondary to={ url } className="FeatureCard__button">{ ctaLabel }</Button>
    </div>
  );
};

FeatureCard.propTypes = propTypes;

export default FeatureCard;
