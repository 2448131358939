import React from 'react';
import { string, arrayOf, shape } from 'prop-types';

import FeatureCard from './FeatureCard';

import './style.scss';

const propTypes = {
  features: arrayOf(shape({
    title: string.isRequired,
    image: shape({
      source: string.isRequired,
      alt: string,
    }).isRequired,
    text: string.isRequired,
    url: string.isRequired,
    ctaLabel: string.isRequired,
  })),
};

const Features = ({ features }) => {
  return (
    <section className="Features">
      { features.map((feature, i) => (
        <FeatureCard { ...feature } key={ feature.title + i } />
      )) }
    </section>
  );
};

Features.propTypes = propTypes;

export default Features;
