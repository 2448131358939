import React from 'react';
import cn from 'classnames';
import { string, arrayOf, shape } from 'prop-types';

import './style.scss';

import WorkTabItem from './WorkTabItem';

import TopBg from '../../assets/images/top.png';
import BottomBg from '../../assets/images/bottom.png';
import TopBgImageSmall from '../../assets/svg/top-mobile.svg';
import BottomBgImageSmall from '../../assets/svg/bottom-mobile.svg';

import WavesTopBg from '../../assets/images/waves-top.png';
import WavesBottomBg from '../../assets/images/waves-bottom.png';

const propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  components: arrayOf(shape({
    title: string.isRequired,
    text: string.isRequired,
    image: shape({
      source: string.isRequired,
      alt: string,
    }).isRequired,
    icon: string.isRequired,
    ctaLabel: string.isRequired,
  })),
};

class WorksTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 0,
    };
  }

  setActive = (key) => {
    this.setState({
      active: key,
    });
  }

  render() {
    const { title, subtitle, components } = this.props;

    return (
      <section className="WorksTabs">

        <img
          src={ WavesTopBg }
          alt="Top background waves"
          className="WorksTabs__background WorksTabs__background--top WorksTabs__background--wide"
        />
        <img
          src={ TopBg }
          alt="Top background"
          className="
            WorksTabs__background
            WorksTabs__background--wrapped
            WorksTabs__background--top
            WorksTabs__background--wide
            WorksTabs__background--top-crooked
          "
        />

        <TopBgImageSmall
          className="
          WorksTabs__background
          WorksTabs__background--top
          WorksTabs__background--narrow
          WorksTabs__background--top-crooked
          "
        />
        <BottomBgImageSmall
          className="
          WorksTabs__background
          WorksTabs__background--bottom
          WorksTabs__background--narrow
          WorksTabs__background--bottom-crooked
          "
        />

        <img
          src={ WavesBottomBg }
          alt="Bottom background waves"
          className="WorksTabs__background WorksTabs__background--bottom WorksTabs__background--wide"
        />

        <img
          src={ BottomBg }
          alt="Bottom background"
          className="
            WorksTabs__background
            WorksTabs__background--wrapped
            WorksTabs__background--bottom
            WorksTabs__background--wide
            WorksTabs__background--bottom-crooked
            "
          />

        <div className="WorksTabs__container">
          <header className="WorksTabs__header">
            <h3 className="WorksTabs__title">{ title }</h3>
            <p className="WorksTabs__subtitle">
              { subtitle }
            </p>
          </header>

          <div className="WorksTabs__tabs">
            { components.map((tab, index) => (
              <React.Fragment key={ tab.title + index }>
                <div
                  className={
                    cn(
                      'WorksTabs__image-container',
                      { 'WorksTabs__image-container--active': index === this.state.active }
                    )
                  }
                >
                  <img
                    className="WorksTabs__image"
                    src={ tab.image.source }
                    alt={ tab.image.alt || tab.title }
                    />
                </div>

                <WorkTabItem
                  { ...tab }
                  active={ index === this.state.active }
                  onClick={ () => this.setActive(index) }
                  className="WorksTabs__tab"
                />
              </React.Fragment>
            )) }
          </div>

        </div>

      </section>
    );
  }
}

WorksTabs.propTypes = propTypes;

export default WorksTabs;
