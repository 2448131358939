import React from 'react';
import cn from 'classnames';
import { string, bool } from 'prop-types';
import ReactSVG from 'react-svg';

import getAppRoute from '../../../utils/getAppRoute';

import Button from '../../Button';

import Arrow from '../../../assets/svg/arrow-right.svg';

import './style.scss';

const propTypes = {
  icon: string.isRequired,
  title: string.isRequired,
  text: string.isRequired,
  ctaLabel: string.isRequired,
  active: bool.isRequired,
  className: string,
};

const defaultProps = {
  className: '',
};

const WorkTabItem = ({ icon, title, text, ctaLabel, active, className, ...rest }) => {
  return (
    <div className={ cn('WorkTabItem', { 'WorkTabItem--active': active }, className) } { ...rest }>

      <span className="WorkTabItem__header">
        <div className="WorkTabItem__icon">
          <ReactSVG src={ icon } />
        </div>
        <h4 className="WorkTabItem__title">{ title }</h4>
      </span>

      <p className="WorkTabItem__text">
        { text }
      </p>

      <Button text to={ getAppRoute('WHY') } className="WorkTabItem__button">
        { ctaLabel }
        <Arrow className="WorkTabItem__button-icon" />
      </Button>
    </div>
  );
};

WorkTabItem.propTypes = propTypes;
WorkTabItem.defaultProps = defaultProps;

export default WorkTabItem;
