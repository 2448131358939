import React from 'react';
import { string, shape } from 'prop-types';

import MobileAppsButtons from '../MobileAppsButtons';

import './style.scss';

const propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  image: shape({
    source: string.isRequired,
    alt: string.isRequired,
  }).isRequired,
};

const Hero = ({ title, subtitle, image }) => {
  return (
    <section className="Hero">
      <div className="Hero__header">
        <h1 className="Hero__title">
          { title }
        </h1>

        <p className="Hero__subtitle">
          { subtitle }
        </p>

        <MobileAppsButtons withText className="Hero__buttons" />
      </div>

      <div className="Hero__image">
        <img src={ image.source } alt={ image.alt } />
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;

export default Hero;
