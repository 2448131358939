import React from 'react';
import { shape, object, array } from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import Hero from '../../components/Hero';
import Features from '../../components/Features';
import WorksTabs from '../../components/WorksTabs';
import Banner from '../../components/Banner';
import Seo from '../../components/Seo';

import './style.scss';

const IndexPageTemplatePropTypes = {
  hero: object.isRequired,
  features: array.isRequired,
  tabs: object.isRequired,
  banner: object.isRequired,
};

export const IndexPageTemplate = ({
  hero,
  features,
  tabs,
  banner,
  seo,
}) => {
  return (
    <>
      <Seo { ...seo } />
      <section className="HomePage">
        <Hero { ...hero }/>
        <Features features={ features } />
        <WorksTabs { ...tabs } />
        <Banner { ...banner } />
      </section>
    </>
  );
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        { ...frontmatter }
      />
    </Layout>
  );
};

const IndexPagePropTypes = {
  data: shape({
    markdownRemark: shape({
      frontmatter: object,
    }),
  }),
};

IndexPageTemplate.propTypes = IndexPageTemplatePropTypes;
IndexPage.propTypes = IndexPagePropTypes;

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        seo {
          title
          description
          image
        }
        hero {
          title
          subtitle
          image {
            source
            alt
          }
        }
        features {
          title
          image {
            source
            alt
          }
          text
          url
          ctaLabel
        }
        tabs {
          title
          subtitle
          components {
            title
            icon
            text
            image {
              source
              alt
            }
            ctaLabel
          }
        }
        banner {
          title
          text
          image {
            source
            alt
          }
        }
      }
    }
  }
`;
