import React from 'react';
import { string, shape } from 'prop-types';

import MobileAppsButtons from '../MobileAppsButtons';

import './style.scss';

const propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  image: shape({
    source: string.isRequired,
    alt: string,
  }),
};

const Banner = ({ title, text, image, alt }) => {
  return (
    <section className="Banner">
      <div className="Banner__header">
        <h3 className="Banner__title">{ title }</h3>
        <p className="Banner__subtitle">{ text }</p>

        <MobileAppsButtons className="Banner__buttons" secondary />
      </div>

      <img src={ image.source } alt={ image.alt || title } className="Banner__image" />
    </section>
  );
};

Banner.propTypes = propTypes;

export default Banner;
